import React from 'react';

import { Button } from './Button';

import * as styles from './ProductGroupEntry.module.css';
import Slugger from '../../helper/Slugger';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const ProductGroupEntry = ({ data }) => {
  const { name, boximage: image } = data;

  const extraPath = `/traktoren/${Slugger.slug(name)}`;

  const navigateToDetailPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(extraPath);
  };

  return (
    <section className={styles.ProductGroupEntry} onClick={navigateToDetailPage} role="presentation">
      <div className={styles.top}>
        <GatsbyImage
          image={getImage(image)}
          alt={name}
          imgStyle={{ height: 200 }}
          style={{ height: 200 }}
          {...image.src}
        />
      </div>
      <div className={styles.logo}>{name}</div>
      <div className={styles.action}>
        <Button onClick={navigateToDetailPage} to={extraPath} icon="arrow-right">
          Weiterlesen
        </Button>
      </div>
    </section>
  );
};
