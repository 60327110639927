import React from 'react';

import * as styles from './Hersteller.module.css';
import PageTitle from './MeinTraktor-Lamborghini-Same/PageTitle';
import { ProductGroupEntry } from './ProductGroupEntry';
import { HerstellerEntry } from './HerstellerEntry';
import classNames from 'classnames';

const sortHerstellerFunction = (a, b) => {
  if (a.starred && b.starred) {
    return a.order - b.order;
  } else if (a.starred) {
    return -1;
  } else if (b.starred) {
    return 1;
  }
  return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
};

const renderHersteller = (data) => <HerstellerEntry data={data} key={data.id} />;

const renderProductGroup = (data) => (data.published ? <ProductGroupEntry data={data} key={data.id} /> : null);

export const Hersteller = ({ data }) => {
  const sortedByCategory = {};
  data.hersteller.edges.forEach(({ node }) => {
    sortedByCategory[node.category] = sortedByCategory[node.category] || [];
    sortedByCategory[node.category].push(node);
  });

  const productGroupsSortedByManufacturer = {};
  data.productGroups.edges.forEach(({ node }) => {
    const manufacturerName = node.manufacturer.name;
    productGroupsSortedByManufacturer[manufacturerName] = productGroupsSortedByManufacturer[manufacturerName] || [];
    productGroupsSortedByManufacturer[manufacturerName].push(node);
  });

  if (data.hersteller.edges[0].node.slug === 'lamborghini') {
    return (
      <section className="defaultWidth" style={{ marginBottom: 8 }}>
        <div className={styles.content}>
          <span id="lambo" className={styles.anchor} />
          {productGroupsSortedByManufacturer.Lamborghini.map(renderProductGroup)}
          <div className={'clearfix'} />
        </div>
        <PageTitle>Zubehör</PageTitle>
        <div className={styles.content} style={{ marginTop: 8 }}>
          <span id="zubehoer" className={styles.anchor} />
          {sortedByCategory.Ersatzteil.sort(sortHerstellerFunction).map(renderHersteller)}
        </div>
      </section>
    );
  } else if (data.hersteller.edges[0].node.slug === 'same') {
    return (
      <section className="defaultWidth" style={{ marginBottom: -8 }}>
        <div className={classNames(styles.content)}>
          <span id="same" className={styles.anchor} />
          {productGroupsSortedByManufacturer.Same.map(renderProductGroup)}
        </div>

        <PageTitle>Zubehör</PageTitle>
        <div className={styles.content} style={{ marginTop: 8 }}>
          <span id="zubehoer" className={styles.anchor} />
          {sortedByCategory.Ersatzteil.sort(sortHerstellerFunction).map(renderHersteller)}
        </div>
      </section>
    );
  }

  return (
    <section className="defaultWidth" style={{ marginBottom: -8 }}>
      <div className={styles.content}>
        <span id="same" className={styles.anchor} />
        {productGroupsSortedByManufacturer.Same.map(renderProductGroup)}
      </div>

      <PageTitle>Zubehör</PageTitle>
      <div className={styles.content}>
        <span id="zubehoer" className={styles.anchor} />
        {sortedByCategory.Ersatzteil.sort(sortHerstellerFunction).map(renderHersteller)}
      </div>
    </section>
  );
};
