import React from 'react';

import { Button } from './Button';

import * as styles from './HerstellerEntry.module.css';
import Slugger from '../../helper/Slugger';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const HerstellerEntry = ({ data, t, locale }) => {
  const { name, logopng, logojpg, logosvg, image } = data;

  const linkToHersteller = `/hersteller/${Slugger.slug(name)}`;

  const hasPng = !!logopng;
  const hasJpg = !!logojpg;
  const hasSvg = !!logosvg;

  const logoToUse = hasPng ? logopng : hasSvg ? logosvg : hasJpg ? logojpg : '';

  const logoUrl = logoToUse ? logoToUse.publicURL : null;

  return (
    <section
      className={styles.HerstellerEntry}
      onClick={(e) => {
        navigate(linkToHersteller);
        e.preventDefault();
      }}
    >
      <div className={styles.top}>
        <GatsbyImage image={getImage(image)} alt={name} imgStyle={{ height: 200 }} style={{ height: 200 }} />
      </div>
      <div className={styles.logo}>{logoUrl && <img src={logoUrl} alt={`Logo ${name}`} />}</div>
      <div className={styles.action}>
        <Button to={linkToHersteller} icon="arrow-right">
          Weiterlesen
        </Button>
      </div>
    </section>
  );
};
